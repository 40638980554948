import { Global, Collection, Jobs } from "@sourceflow-uk/sourceflow-sdk";
import SeoHead from "../components/SeoHead";
import LatestAwards from "../components/LatestAwards";
import LatestNews from "../components/LatestNews";
import MainBanner from "../components/MainBanner";
import LatestJobs from "../components/LatestJobs";
import ImageWithVideoBanner from "../components/ImageWithVideoBanner";
import ImageWithTextWithOverlay from "../components/ImageWithTextWithOverlay";
import LatestTestimonials from "../components/LatestTestimonials";
import MultiColumnCTA from "../components/MultiColumnCTA";
import Feefo from "components/Feefo";

export default function Home({
  global,
  all_awards,
  all_news,
  all_jobs,
  all_solutions,
  all_sectors,
  all_testimonials,
}) {
  return (
    <>
      <SeoHead />
      <MainBanner
        global={global}
        pathPrefix={`homepage.main_banner`}
        customClass={``}
        content={
          <>
            <h1>
              Industry Leaders in Life<br></br>Sciences Recruitment
            </h1>
            <p>
              SRG is the leader in the Life Sciences human capital industry. We
              apply three decades of specialized knowledge to a full spectrum of
              clinical, quality, regulatory, and compliance talent solutions. We
              recruit across the Life Sciences product life cycle—covering
              scientific research and development (R&D), clinical trials,
              manufacturing, and engineering disciplines.
            </p>
            <p>
              Together, we create world-class communities that empower
              individuals and businesses to shape tomorrow’s world.
            </p>
          </>
        }
        imageURL1={`/site-assets/images/main-banner.webp`}
        imageALT1={`Science lab workers looking at test tube`}
        imageURL2={`/site-assets/images/main-banner-overlay.jpg`}
        imageALT2={`Overlay Main Banner`}
        jobSearch={true}
        pageAnchor={`more-info-of-us`}
        h1_content={
          <>
            Industry Leaders in <br></br>Life Sciences Recruitment
          </>
        }
      />
      <ImageWithTextWithOverlay
        global={global}
        pathPrefix={`synergy_scientific_page.image_with_text_with_overlay`}
        customClass={`py-5`}
        backgroundColor={"#ffffff"}
        content={
          <>
            <h2>Lab Leaders Vision 2025 </h2>
            <p>
              Discover strategies for success with insights from over 1,000
              surveyed lab leaders, plus expert guidance and advice. Explore
              workforce challenges, automation and efficiency strategies to
              drive lab innovation and resilience.
            </p>
          </>
        }
        buttonTitle={`Download Now`}
        buttonLink={`/lab-leader-vision-2025`}
        imageURL={`/site-assets/images/Synergy_Lab_Leaders.png`}
        imageALT={`Job Seekers Featured Image`}
        popUpModal_1={false}
      />
      <ImageWithVideoBanner
        global={global}
        pathPrefix={`homepage.image_with_video_banner`}
        sectionId={`what-we-do`}
        customClass={`py-5`}
        content={
          <>
            <h2>It's What We Do</h2>
          </>
        }
        buttonTitle={`Learn More About What We Do`}
        buttonLink={`/`}
        videoLink={`https://www.youtube.com/watch?v=zjC4RvsWsdk`}
        bannerImage={``}
        imageURL={`/site-assets/images/what-we-do.webp`}
        imageALT={`It's what we do`}
        videoGalleryClass={`WhatWeDo`}
        pageAnchor={`more-info-of-us`}
      />
      <div className="py-5">
        <ld-slot name="Homepage Client Solutions"></ld-slot>
      </div>
      <LatestJobs
        global={global}
        pathPrefix={`homepage.latest_jobs`}
        customClass={`py-5`}
        post={all_jobs}
        content={
          <>
            <h2>Hot Jobs</h2>
            <p>Find the best tech and digital career opportunities.</p>
          </>
        }
        buttonTitle={`View All Jobs`}
        buttonLink={`/jobs/`}
        swiperID={`latestJobsSwiper`}
      />
      <MultiColumnCTA
        global={global}
        pathPrefix={`homepage.multi_column_cta`}
        sectionId={`more-info-of-us`}
        customClass={`py-5`}
        left_content={
          <>
            <h2>Market's We Recruit</h2>
          </>
        }
        right_content={
          <>
            <h2>Business Solutions</h2>
          </>
        }
        left_post={all_sectors}
        right_post={all_solutions}
        leftColumnFilterUrl={`sectors/`}
      />
      <LatestTestimonials
        global={global}
        pathPrefix={`homepage.latest_testimonials`}
        customClass={`py-5`}
        content={
          <>
            <h2>Candidate Testimonials</h2>
          </>
        }
        post={all_testimonials}
        swiperID={`latestTestimonialsSwiper`}
      />
      <LatestNews
        global={global}
        pathPrefix={`homepage.latest_news`}
        customClass={`py-5`}
        post={all_news}
        content={
          <>
            <h2>The Lorien Pulse: Latest News, Events, and Insights</h2>
            <p>
              Our tech expertise and partner-led approach allows us to deliver
              highly successful results for our clients so they can attract and
              retain unique talent for their business. Explore our latest
              collection of case studies, news, events, and insights
            </p>
          </>
        }
        buttonLink={`/blog/`}
        buttonTitle={`View All Insights`}
      />
      <LatestAwards
        global={global}
        pathPrefix={`homepage.latest_awards`}
        customClass={`pt-5`}
        content={
          <>
            <h2>Awards & Industry Recognition</h2>
          </>
        }
        post={all_awards}
        buttonLink={``}
        buttonTitle={``}
        swiperID={`latestAwardsSwiper`}
      />
      <Feefo />
    </>
  );
}

export async function getStaticProps({ params }) {
  const global = (await Global()).toJson();
  const awards = await Collection("awards_industry_recognition");
  const solutions = await Collection("solutions");
  const sectors = await Collection("sectors_uk");

  const testimonials = (await Collection("candidate_testimonials")).filter(
    function (testimonial) {
      return testimonial.tags?.en == "Homepage";
    }
  );

  const news = (await Collection("news")).filter(function (post) {
    return post.country?.en == "United Kingdom" || post.country?.en == "All";
  });

  const all_jobs = (await Jobs())
    .filter(function (job) {
      return (
        job.categories
          // Pulls Sectors only
          .find(
            (cat) =>
              cat.id === "b4b8be3c-ba95-4912-aa85-2cbc1264449d" &&
              cat.values.find((x) => x.name === "United Kingdom")
          )
      );
    })
    .filter(function (job) {
      return new Date(job?.expires_at) >= new Date();
    })
    .getItems()
    .slice(0, 8);

  const all_solutions = solutions.orderBy("order").getItems();
  const all_sectors = sectors.orderBy("order").getItems();
  const all_awards = awards.orderBy("order").getItems();
  const all_news = news.orderBy("date").getItems().slice(0, 8);

  const all_testimonials = testimonials.orderBy("order").getItems();
  return {
    props: {
      global,
      all_awards,
      all_news,
      all_jobs,
      all_solutions,
      all_sectors,
      all_testimonials,
    },
  };
}
